import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const RecipePage = () => (
    <Layout metaTitle="Bacon Klondike® Bar Bonbons"
        metaDescription="Try our version of Bonbons with thick-cut bacon sprinkled on top of a Klondike® bar cut up into four pieces. This sweet and salty dessert is sure to impress!"
        metaKeywords=""
    >
        <meta property="og:image" content={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/Wright-Brand-Recipe-Klondike-BonBons-Lifestyle-1367×737.jpg'} />
        <link rel="image_src" href={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/Wright-Brand-Recipe-Klondike-BonBons-Lifestyle-1367×737.jpg'} />

        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/Wright-Brand-Recipe-Klondike-BonBons-Lifestyle-1367×737.jpg'} class='img-responsive' alt="The Wright Brand Klondike Bonbons" />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>The Wright<sup>&reg;</sup> Brand Klondike<sup>&reg;</sup> Bonbons</h1>

                            <div class="recipe-content">
                                <p >Bite-sized treats that cure your salty and sweet cravings</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        30&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        20-25&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>4 </p>
                                </div>
                            </div>
                        </div>

                        <div></div>
                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom">Strips</span> of Wright® Brand Hickory Smoked Bacon, fully cooked
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                Finely <span class="uom">Chopped</span> 1 Klondike® bar
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Place chopped Wright® Brand bacon in a small dish.

                </p>
                            <p>
                                2. Take a Klondike® bar and use a large knife to cut off the four edges. Now cut the Klondike® bar into four, right down the center and press each side of each cube into the bacon pieces.

                </p>
                            <p>
                                3. Wrap in plastic wrap and freeze for at least one hour before serving like Bonbons.
                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
